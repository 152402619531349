import React from "react";

import "./TermsandConditions.css";

const TermsandConditions = () => {
  return (
    <>
      <div className="termsContainer">
        <h2>TERMS & CONDITIONS</h2>
        <div className="termsContent">
          <h6>Your use of this website is governed by these terms of use</h6>
          <p>
            Welcome to our website. By accessing and using this website, you accept and agree to be bound by the terms and provisions of this agreement. If you do not agree to abide by these terms, please do not use this website. We reserve the right to modify these terms at any time without prior notice. Your continued use of this website following any changes constitutes your acceptance of those changes.
          </p>
          <h6>Use of the content on this website</h6>
          <p>
            All content on this website, including text, graphics, logos, images, and software, is the property of our company and is protected by copyright laws. You may access, download, or print content from this website for your personal, non-commercial use only. Any other use, including reproduction, modification, distribution, or republication without prior written permission is strictly prohibited.
          </p>
          <h6>How long will it take to get my package?</h6>
          <p>
            Standard delivery typically takes 3-5 business days from the date of shipment. Express delivery options are available at checkout for 1-2 business day delivery. Please note that delivery times may vary based on your location and other factors outside our control, such as weather conditions or carrier delays. You will receive a tracking number via email once your order has been shipped.
          </p>
          <h6>Will I receive the same product that I see in the picture?</h6>
          <p>
            We make every effort to display products as accurately as possible. However, we cannot guarantee that your computer monitor's display of any color or detail will be accurate. While we strive to provide accurate product descriptions and images, slight variations may occur. If you receive an item that is substantially different from what was displayed, please contact our customer service team within 14 days of receipt.
          </p>
          <h6>Limitation of liability</h6>
          <p>
            In no event shall our company be liable for any direct, indirect, punitive, incidental, special, or consequential damages arising out of, or in any way connected with, your use of this website or with any delay or inability to use this website, whether based on contract, tort, strict liability, or otherwise, even if we have been advised of the possibility of damages. In jurisdictions that do not allow the exclusion or limitation of liability for consequential or incidental damages, our liability shall be limited to the maximum extent permitted by law.
          </p>
          <h6>Governing Law & Jurisdiction</h6>
          <p>
            These terms and conditions are governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles. Any dispute arising under or relating to these terms shall be subject to the exclusive jurisdiction of the courts located in [Your Jurisdiction]. You agree to submit to the personal jurisdiction of these courts for any actions related to your use of our website.
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsandConditions;
