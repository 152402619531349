import React from "react";
import "./AboutPage.css";

import about1 from "../../Assets/About/about-1.jpg";
import about2 from "../../Assets/About/about-2.jpg";

import Services from "../../Components/Home/Services/Services";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";

import brand1 from "../../Assets/Brands/brand1.png";
import brand2 from "../../Assets/Brands/brand2.png";
import brand3 from "../../Assets/Brands/brand3.png";
import brand4 from "../../Assets/Brands/brand4.png";
import brand5 from "../../Assets/Brands/brand5.png";
import brand6 from "../../Assets/Brands/brand6.png";
import brand7 from "../../Assets/Brands/brand7.png";

const AboutPage = () => {
  return (
    <>
      <div className="aboutSection">
        <h2>About wevi</h2>
        <img src={about1} alt="" />
        <div className="aboutContent">
          <h3>Our Story</h3>
          <h4>
            Born from a passion for timeless elegance and contemporary expression, Wevi has evolved into a fashion house that celebrates individuality and craftsmanship.
          </h4>
          <p>
            Wevi began in 2015 when our founder, a textile designer with a vision for sustainable luxury, created a small collection of handcrafted pieces that quickly captured attention in the fashion world. What started as an intimate atelier in a historic district has blossomed into an internationally recognized brand. Our journey has been defined by a commitment to ethical sourcing, artisanal techniques, and designs that transcend seasonal trends. Each Wevi piece tells a story—of the hands that crafted it, the materials thoughtfully selected, and the artistic heritage that inspires our aesthetic direction.
          </p>
          <div className="content1">
            <div className="contentBox">
              <h5>Our Mission</h5>
              <p>
                To create fashion that honors craftsmanship and self-expression while respecting our planet and the communities that bring our designs to life.
              </p>
            </div>
            <div className="contentBox">
              <h5>Our Vision</h5>
              <p>
                To redefine luxury as a mindful choice that balances beauty, quality, and responsibility, inspiring a more conscious approach to personal style.
              </p>
            </div>
          </div>
          <div className="content2">
            <div className="imgContent">
              <img src={about2} alt="" />
            </div>
            <div className="textContent">
              <h5>The Company</h5>
              <p>
                Today, Wevi stands at the intersection of artistry and innovation. Our design studio collaborates with artisans across three continents, combining traditional techniques with modern sensibilities. We've pioneered the use of regenerated fabrics alongside heritage materials, creating collections that feel both familiar and revolutionary. Our flagship boutiques in Paris, New York, and Tokyo offer immersive spaces where clients can experience the full expression of our brand ethos. Beyond creating beautiful garments, we're building a community that values the stories behind what we wear and the impact of our choices.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Services />
      <div className="companyPartners">
        {/* <h5>Company Partners</h5> */}
        {/* <Swiper
          slidesPerView={1}
          loop={true}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 5,
            },

            768: {
              slidesPerView: 4,
              spaceBetween: 40,
            },

            1024: {
              slidesPerView: 5,
              spaceBetween: 50,
            },
          }}
          spaceBetween={10}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
        >
          <SwiperSlide>
            <div className="aboutBrands">
              <img src={brand1} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="aboutBrands">
              <img src={brand2} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="aboutBrands">
              <img src={brand3} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="aboutBrands">
              <img src={brand4} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="aboutBrands">
              <img src={brand5} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="aboutBrands">
              <img src={brand6} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="aboutBrands">
              <img src={brand7} alt="" />
            </div>
          </SwiperSlide>
        </Swiper> */}
      </div>
    </>
  );
};

export default AboutPage;
